.title {
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
    text-align: center;
}

.description {
    color: #ffffff;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.title-end {
    color: #02B4BA;
}

@media (min-width: 900px) {
    .title {
        font-size: 72px;
        line-height: 80px;
        max-width: 60%;
        text-align: left;
    }
    .description {
        font-size: 20px;
        line-height: 27px;
        text-align: left;
    }
}
