.bgimg-container {
    background-image: linear-gradient(
            180.07deg,
            rgba(0, 0, 0, 0.8) 18.76%,
            rgba(4, 34, 51, 0.7) 63.22%,
            rgba(13, 103, 153, 0.2) 99.94%
        ),
        var(--img);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.wrapper-container {
    width: 100%;
    position: relative;
}

.img-vector {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 62%;
    width: 75%;
    opacity: 0.3px;
}

@media (min-width: 900px) {
    .img-vector {
        width: 30rem;
        height: 14rem;
    }
}
