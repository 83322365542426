.grid {
    display: grid;
    grid-template-rows: repeat(auto-fill, 200px) 20%;
    grid-gap: 16px;
    padding: 10px;
}
.image-block {
    block-size: none;
}
.image-block.byindex {
    block-size: 6em;
    grid-column-end: span 2;
}

.layout {
    display: flex;
    flex-direction: column;
}

.grid-item {
    background: #eee;
    border-radius: 8px;
    overflow: hidden;
}

.masonry-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    grid-template-areas:
        'item1 item2 item3 item3'
        'item4 item5 item5 item6';
}

.masonry-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.masonry-item img {
    width: 100%;
    height: 'auto';
    display: block;
    object-fit: cover;
}

@media (max-width: 420px) {
    .masonry-grid {
        grid-template-columns: max-content;
        grid-template-areas:
            'item1 item2'
            'item3 item4'
            'item5 item6';
    }
    .card {
        width: 170px;
        height: 185px;
    }
}
