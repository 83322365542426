#productCard {
    position: relative;
}

#productCard:hover > .MuiCardMedia-root{
    height: 100%;
    
}
#productCard:hover p, #productCard:hover h6, #productCard:hover svg{
    color: #FFFFFF;
    transition: 1.2s;
}

#productCard:hover svg path {
    fill: #FFFFFF;
    transition: 1.0s;
}

#productCard > .MuiCardMedia-root {
    width: 100%;
    height: 10rem;
    transition: 1.0s;
    position: absolute;

}

#productCard > .MuiBox-root {
    position: absolute;
    padding-bottom: 15px;
    bottom: 0;

}

@media (max-width: 420px) {
    .MuiCardMedia-root {
        height: 8rem;
        transition: 1.0s;
    }
}