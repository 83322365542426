.card {
    position: relative;
    width: 'auto';
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    transition: transform 0.5s ease-in-out;
    pointer-events: none;
}

.card:hover .card-overlay {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
}

.rating {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 9999;
}
/**
*/

/*font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 38px;
/* identical to box height */

.subtitle {
    position: absolute;
    font-family: 'DM Serif Text';
    line-height: 38px;
    bottom: -10px;
    left: 20px;
    z-index: 9999;
    color: #ffffff;
    transition: transform 0.5s ease-in-out;
}

.subtitle.onhover {
    transform: scale(1.1);
    transform-origin: 0% 75%;
}

.stars-container {
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    padding-left: 8px;
    padding-right: 8px;
    transition: transform 0.5s ease-in-out;
}
.stars-container.onhover {
    transform: scale(1.1);
    transform-origin: 0% -75%;
}

.star-svg {
    color: #ffc107;
    transition: transform 0.5s ease-in-out;
}

.star-svg.onhover {
    padding-right: 1.5px;
    transform: rotate(70deg) scale(1.05);
    transform-origin: 0% 25% 25% 0%;
}

@media (max-width: 420px) {
    .card {
        width: 170px;
        height: 185px;
    }
    .subtitle {
        font-size: 1.125rem;
    }
}
