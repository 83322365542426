.hero-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    background-color: rgba(2, 180, 186, 1);
    width: 100%;
}

@media (min-width: 900px) {
    .hero-bottom {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }
}
